import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { StandardLeasePopup } from './LeasePopUps/StandardLeasePopup';
import { AdvancedLeasePopup } from './LeasePopUps/AdvancedLeasePopup';
import { ExclusiveRightsPopup } from './LeasePopUps/ExclusiveRightsPopup';
import Notifications from '../components/Notifications';
import { displayError, displaySuccess } from '../components/utils';

import './signup.css';

const ContributorSetup = () => {
  const { getIdTokenClaims } = useAuth0();
  const [errorMessages, setErrorMessages] = useState('');
  const [successMessages, setSuccessMessages] = useState('');
  const [selectedOption, setSelectedOption] = useState('Producer');
  const [showPricingForm, setShowPricingForm] = useState(false);
  const [showContractForm, setShowContractForm] = useState(false);
  const [selectedContractType, setSelectedContractType] = useState('');
  const [showAccountSettingsPopup, setShowAccountSettingsPopup] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [pricingData, setPricingData] = useState({
    price_standard: '',
    price_advanced: '',
    price_exclabel: '',
    price_excindie: '',
  });
  const [contractData, setContractData] = useState({
    standlease_terms: {
      term_years: '3',
      distribution_copies: '1000',
      audio_streams: '10000',
      free_downloads: '5000',
      vid_streams: '10000',
      nonmonvid_streams: '10000',
      mv_streams: '10000',
      nonmonmv_streams: '10000',
      Broadcast_rights: 'No',
      Performance_rights: 'No',
      Nonprofit_performances: 'Yes',
      Synchronization_rights: 'No',
      additional_comments: '',
    },
    advancedlease_terms: {
        term_years: '5',
        distribution_copies: '250000',
        audio_streams: '250000',
        free_downloads: '100000',
        vid_streams: '500000',
        nonmonvid_streams: '500000',
        mv_streams: '500000',
        nonmonmv_streams: '500000',
        Broadcast_rights: 'No',
        Performance_rights: 'Yes',
        Nonprofit_performances: 'Yes',
        Synchronization_rights: 'No',
        additional_comments: '',
    },
    exclabel_terms: {
      sell_exclusives: 'No',
    },
    excindie_terms: {
      sell_exclusives: 'No',
    },
  });

  const fetchPricingAndTermsData = async () => {
    try {
      const idTokenClaims = await getIdTokenClaims();
      const idToken = idTokenClaims.__raw;
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/producer-data`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
        credentials: 'include',
      });
      if (response.ok) {
        const data = await response.json();
        setPricingData(data.pricingData);
        setContractData(data.contractData);
      } else {
        console.error('Error fetching pricing and terms data:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching pricing and terms data:', error);
    }
  };
  
  useEffect(() => {
    fetchPricingAndTermsData();
  }, []);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  
  const handleStripeAccountClick = async () => {
    try {
      const idTokenClaims = await getIdTokenClaims();
      const idToken = idTokenClaims.__raw;
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/express-dashboard`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
        credentials: 'include',
      });
      if (response.ok) {
        const data = await response.json();
        window.location.href = data.url;
      } else {
        console.error('Error accessing Stripe account:', response.statusText);
      }
    } catch (error) {
      console.error('Error accessing Stripe account:', error);
    }
  };
  
  const handlePricingClick = () => {
    setShowPricingForm(!showPricingForm);
    setShowContractForm(false);
  };
  
  const openContractPopup = (contractType) => {
    setSelectedContractType(contractType);
    setShowPopup(true);
  };

  const closeContractPopup = () => {
    setSelectedContractType('');
    setShowPopup(false);
  };
  const handleContractClick = () => {
    setShowContractForm(!showContractForm);
    setShowPricingForm(false);
  };

  const fetchProducerData = async () => {
    try {
      const idTokenClaims = await getIdTokenClaims();
      const idToken = idTokenClaims.__raw;
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/producer-account`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
        credentials: 'include',
      });
      if (response.ok) {
        const data = await response.json();
        setProducerData(data.producer);
      } else {
        console.error('Error fetching producer data:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching producer data:', error);
    }
  };

  useEffect(() => {
    fetchProducerData();
  }, []);


const updateProducerData = async () => {
  try {
    const idTokenClaims = await getIdTokenClaims();
    const idToken = idTokenClaims.__raw;
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/producer-account`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${idToken}`,
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(producerData),
    });
    if (response.ok) {
      console.log('Producer data updated successfully');
      displaySuccess(setSuccessMessages)('Producer data updated successfully.');
    } else {
      console.error('Error updating producer data:', response.statusText);
      displayError(setErrorMessages)('Error updating producer data.');
    }
  } catch (error) {
    console.error('Error updating producer data:', error);
    displayError(setErrorMessages)('Error updating producer data.');
  }
};

const [producerData, setProducerData] = useState({
  first_name: '',
  last_name: '',
  birth_date: '',
  prod_name: '',
  prod_email: '',
  prod_pro: '',
  prod_pro_id: '',
  prod_masters_info: null,
});


  const handlePricingChange = (event) => {
    setPricingData({
      ...pricingData,
      [event.target.name]: event.target.value,
    });
  };

  const handleContractChange = (contractType, updatedData) => {
    setContractData({
      ...contractData,
      [contractType]: updatedData,
    });
  };

  const handlePricingSave = async () => {
    try {
      const idTokenClaims = await getIdTokenClaims();
      const idToken = idTokenClaims.__raw;
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/producer-pricing`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${idToken}`,
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(pricingData),
      });
      if (response.ok) {
        console.log('Pricing data saved successfully');
        setShowPricingForm(false);
        displaySuccess(setSuccessMessages)('Pricing data saved successfully.');
      } else {
        console.error('Error saving pricing data:', response.statusText);
        displayError(setErrorMessages)('Error saving pricing data.');
      }
    } catch (error) {
      console.error('Error saving pricing data:', error);
      displayError(setErrorMessages)('Error saving pricing data.');
    }
  };

  const handleContractSave = async () => {
    try {
      const idTokenClaims = await getIdTokenClaims();
      const idToken = idTokenClaims.__raw;
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/producer-contract`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${idToken}`,
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(contractData),
      });
      if (response.ok) {
        console.log('Contract data saved successfully');
        closeContractPopup();
        setShowContractForm(false);
        displaySuccess(setSuccessMessages)('Contract data saved successfully.');
      } else {
        console.error('Error saving contract data:', response.statusText);
        displayError(setErrorMessages)('Error saving contract data.');
      }
    } catch (error) {
      console.error('Error saving contract data:', error);
      displayError(setErrorMessages)('Error saving contract data.');
    }
  };
const handleDownloadAgreement = async () => {
  try {
    const idTokenClaims = await getIdTokenClaims();
    const idToken = idTokenClaims.__raw;
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/producer-agreement`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
      credentials: 'include',
    });
    if (response.ok) {
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'producer_agreement.pdf');
      document.body.appendChild(link);
      link.click();
      link.remove();
      displaySuccess(setSuccessMessages)('Producer agreement downloaded successfully.');
    } else {
      console.error('Error downloading producer agreement:', response.statusText);
      displayError(setErrorMessages)('Error downloading producer agreement.');
    }
  } catch (error) {
    console.error('Error downloading producer agreement:', error);
    displayError(setErrorMessages)('Error downloading producer agreement.');
  }
};

const AccountSettingsPopup = ({ producerData, onClose, onSave }) => {
  const [formData, setFormData] = useState(producerData);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div className="popup-overlay">
      <div className="popup">
        <div className="popup-content">
          <h2>Account Settings</h2>
          <div className="form-group">
            <label htmlFor="first_name">First Name:</label>
            <input
              type="text"
              id="first_name"
              name="first_name"
              value={formData.first_name}
              disabled
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label htmlFor="last_name">Last Name:</label>
            <input
              type="text"
              id="last_name"
              name="last_name"
              value={formData.last_name}
              disabled
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label htmlFor="birth_date">Birth Date:</label>
            <input
              type="date"
              id="birth_date"
              name="birth_date"
              value={formData.birth_date}
              disabled
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label htmlFor="prod_name">Producer Name:</label>
            <input
              type="text"
              id="prod_name"
              name="prod_name"
              value={formData.prod_name}
              disabled
              className="form-control"
            />
          </div>
          {/* Add similar input fields for last_name, birth_date, and prod_name */}
          <div className="form-group">
            <label htmlFor="prod_pro">Pro:</label>
            <input
              type="text"
              id="prod_pro"
              name="prod_pro"
              value={formData.prod_pro}
              onChange={handleChange}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label htmlFor="prod_pro_id">Pro ID:</label>
            <input
              type="text"
              id="prod_pro_id"
              name="prod_pro_id"
              value={formData.prod_pro_id}
              onChange={handleChange}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label htmlFor="prod_email">Email:</label>
            <input
              type="email"
              id="prod_email"
              name="prod_email"
              value={formData.prod_email}
              onChange={handleChange}
              className="form-control"
            />
          </div>
          <div className="btn-container">
            <button className="btn btn-primary" onClick={() => onSave(formData)}>
              Save
            </button>
            <button className="btn btn-secondary" onClick={onClose}>
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};


return (

  
  <div className="contributor-setup">
    <Notifications
      errorMessages={errorMessages}
      successMessages={successMessages}
      setErrorMessages={setErrorMessages}
      setSuccessMessages={setSuccessMessages}
      displayError={displayError}
      displaySuccess={displaySuccess}
    />


    <div className="contributor-setup">
      <h1>Contributor Account Setup</h1>
      <div className="form-group">
        <label htmlFor="contributorType">Select Contributor Type:</label>
        <select
          id="contributorType"
          value={selectedOption}
          onChange={handleOptionChange}
          className="form-control"
        >
          <option value="Producer">Producer</option>
          <option value="Songwriter" disabled>
            Songwriter (Coming Soon)
          </option>
          <option value="Vocalist" disabled>
            Vocalist (Coming Soon)
          </option>
        </select>
      </div>


      <div className="btn-container">
        <button className=" bg-sky-400 hover:bg-blue-600 px-8 py-4 text-lg rounded" onClick={handleStripeAccountClick}>
          Modify Stripe Information
        </button>


        <button className="btn btn-secondary" onClick={() => setShowAccountSettingsPopup(true)}>
            Producer Account Settings
          </button>


        {selectedOption === 'Producer' && (
          <>
            <button className="btn btn-secondary" onClick={handlePricingClick}>
              Modify Pricing Options
            </button>
            <button className="btn btn-secondary" onClick={handleContractClick}>
              Modify Contract Options
            </button>
            <button className=" bg-sky-400 hover:bg-blue-600 px-8 py-4 text-lg rounded" onClick={handleDownloadAgreement}>
              Download Producer Agreement
            </button>
            <Link to="/upload-samples" className="btn bg-green-500 hover:bg-green-600 px-8 py-4 text-lg rounded text-white">
              Upload Melodies
            </Link>
          </>
        )}
      </div>

      
      {showPricingForm && (
        <div className="pricing-form">
          <h2>Pricing Options</h2>
          <div className="form-group">
            <label htmlFor="price_standard">Standard Lease Price:</label>
            <input
              type="number"
              id="price_standard"
              name="price_standard"
              value={pricingData.price_standard}
              onChange={handlePricingChange}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label htmlFor="price_advanced">Advanced Lease Price:</label>
            <input
              type="number"
              id="price_advanced"
              name="price_advanced"
              value={pricingData.price_advanced}
              onChange={handlePricingChange}
              className="form-control"
            />
          </div>

          <div className="form-group">
            <label htmlFor="price_excindie">Exclusive Indie Price:</label>
            <input
              type="number"
              id="price_excindie"
              name="price_excindie"
              value={pricingData.price_excindie}
              onChange={handlePricingChange}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label htmlFor="price_exclabel">Exclusive Label Price:</label>
            <input
              type="number"
              id="price_exclabel"
              name="price_exclabel"
              value={pricingData.price_exclabel}
              onChange={handlePricingChange}
              className="form-control"
            />
          </div>
          <button className="btn btn-primary" onClick={handlePricingSave}>
            Save Pricing
          </button>
        </div>
      )}

      {showContractForm && (
        <div className="contract-form">
          <h2>Contract Terms Options</h2>
          <div className="contract-buttons">
            <button
              className="btn btn-secondary"
              onClick={() => openContractPopup('standlease_terms')}
            >
              Standard Lease
            </button>
            <button
              className="btn btn-secondary"
              onClick={() => openContractPopup('advancedlease_terms')}
            >
              Advanced Lease
            </button>
            <button
              className="btn btn-secondary"
              onClick={() => openContractPopup('excindie_terms')}
            >
              Exclusive Indie
            </button>
            <button
              className="btn btn-secondary"
              onClick={() => openContractPopup('exclabel_terms')}
            >
              Exclusive Label
            </button>

          </div>
        </div>
      )}


      {showPopup && (
        <div className="popup-overlay">
          
          {selectedContractType === 'standlease_terms' && (
            <StandardLeasePopup
              contractData={contractData.standlease_terms}
              onContractChange={(updatedData) =>
                handleContractChange('standlease_terms', updatedData)
              }
              onClose={closeContractPopup}
              onSave={handleContractSave}
            />
          )}
          {selectedContractType === 'advancedlease_terms' && (
            <AdvancedLeasePopup
              contractData={contractData.advancedlease_terms}
              onContractChange={(updatedData) =>
                handleContractChange('advancedlease_terms', updatedData)
              }
              onClose={closeContractPopup}
              onSave={handleContractSave}
            />
          )}
          {selectedContractType === 'exclabel_terms' && (
            <ExclusiveRightsPopup
              contractData={contractData.exclabel_terms}
              onContractChange={(updatedData) =>
                handleContractChange('exclabel_terms', updatedData)
              }
              onClose={closeContractPopup}
              onSave={handleContractSave}
            />
          )}
          {selectedContractType === 'excindie_terms' && (
            <ExclusiveRightsPopup
              contractData={contractData.excindie_terms}
              onContractChange={(updatedData) =>
                handleContractChange('excindie_terms', updatedData)
              }
              onClose={closeContractPopup}
              onSave={handleContractSave}
            />
          )}
        </div>
      )}

{showAccountSettingsPopup && (
  <div className="popup-overlay">
    <AccountSettingsPopup
      producerData={producerData}
      onClose={() => setShowAccountSettingsPopup(false)}
      onSave={(updatedData) => {
        setProducerData(updatedData);
        updateProducerData();
        setShowAccountSettingsPopup(false);
      }}
    />
  </div>
)}

    </div>
  </div>

);

};

export default ContributorSetup;